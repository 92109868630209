<template>
    <!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					비회원 예약조회
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
    <!-- // nav -->
    <div id="container" class="container container--fit">
		<div id="sub" class="sub guest">
			<!-- content -->
			<section class="content">
				<div class="centered centered--large">
					<div class="centered__wrap">
						<div class="textbox">
							<div class="textbox__icon">
								<img src="@/assets/images/img_pencil.png" alt="예약내역이 없습니다">
							</div>
							<h3 class="textbox__title">예약내역이 없어요</h3>
                            <p class="textbox__description">예약정보를 다시 확인해 주세요</p>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>